<!--  -->
<template>
  <div class='buy-ok'>
    <div class="buy round bj-col-white pad">
      <img src="../assets/img/chenggong.png" alt="">
      <span>支付成功</span>
      <!-- <el-button type="success" plain size="medium" @click="handleSkip('Fapiao')">我要开发票</el-button> -->
    </div>
    <div class="footer round bj-col-white pad">
      <div>
        <h3>手机学习方式</h3>
        <img src="../assets/img/code/study-h5.png" alt="">
        <span>扫公众号二维码关注进入学习</span>
      </div>
      <div>
        <h3>电脑学习方式</h3>
        <img src="../assets/img/kaoshihint.png" alt="">
        <div class="link" @click="handleSkip()">请点击进入学习</div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      center>
      <div class="success">
        <h3>专属老师微信</h3>
        <p>快扫码添加你的专属老师微信</p>
        <img src="../assets/img/code/kefu.jpg" alt="" class="code">
        <div>服务时间：周一至周五8:30～17；30</div>
      </div>
      <span slot="footer"></span>
    </el-dialog>

    <el-dialog
      :visible.sync="show"
      width="60%"
      @close="onClose"
      class="gongxu"
      center>
      <mai-ke :show="true" :zyYear="year"></mai-ke>
      <span slot="footer">
        <!-- <el-button round type="success" class="self-button--success" size="mini" @click="show = false">跳过</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../api/my'

import MaiKe from './maike.vue'

export default {
  components: {
    MaiKe
  },
  data() {
    return {
      dialogVisible: false,
      type: 1,
      show: false,
      year: ''
    };
  },
  computed: {},
  watch: {},
  created() {
    this.type = this.$route.query.type
    this.year = this.$route.query.year || ''
    // setTimeout(() => {
    //   this.dialogVisible = true
    // }, 3000)
    this.getUserInfo()
  },
  mounted() {
    this.$nextTick(() => {
      let info = JSON.parse(localStorage.getItem('userInfo'))
      if (this.type == 1 && info.xuanke_gx == 0 && this.year != 2020) {
        this.show = true
      } else {
        setTimeout(() => {
          this.dialogVisible = true
        }, 3000)
      }
    })
  },
  //方法集合
  methods: {
    getUserInfo () {
      Api.getUserInfo().then(res => {
        if (res.status == 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
        } else {
          this.$message.error("用户信息获取失败")
        }
      })
    },
    onClose () {
      setTimeout(() => {
        this.dialogVisible = true
      }, 3000)
    },
    handleSkip () {
      let name = this.type == 1 ? 'Xuanke' : 'Gongxu'
      this.$router.replace({name: name})
    }
  },
}
</script>

<style lang='less' scoped>
.buy-ok {
  .buy {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    span {
      margin: 25px 0;
    }
  }
  .gongxu {
    /deep/ .el-dialog__body {
      padding: 25px 25px 0;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &::after {
      content: '';
      width: 1px;
      height: 70%;
      background-color: #D8D8D8;
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translate(-50%, 0);
    }
    > div {
      margin: 50px 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
        margin: 20px 0;
      }
    }
  }

  .success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      margin: 25px 0;
    }
    p {
      color: #666666;
      margin-bottom: 25px;
      font-size: 14px;
      text-align: center;
    }
    .code {
      width: 150px;
      margin: 0 0 40px;
    }
  }
}
</style>