<!--  -->
<template>
  <div class='xuexi round bj-col-white pad'>
    <div class="heard">
      <h2>公需课（免费）报名</h2>
    </div>
    <div class="nav">
      <div class="prompt">
        <div class="year">
          <span>选择年份</span>
          <el-select v-model="year" placeholder="请选择" @change="changeYear" class="picker">
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <span>公需课程包：请选择其中一个课程“确认报名”。</span>
        <!-- <span v-if="show">注：如您已在其它机购已报名公需看，请点击“跳过”。</span> -->
      </div>
      <div class="code">
        <img src="../assets/img/code/study-h5.png" alt="">
        <span>手机学习请扫公众号二维码</span>
      </div>
    </div>

    <div class="list">
      <div v-for="(item, index) in list" :key="index">
        <div>
          <div class="content">
            <h2>{{year}}年公需科目</h2>
            <div>{{item.group_name}}</div>
          </div>
        </div>
        <div>
          <el-button round type="success" class="self-button--success" size="mini" @click="handleStudy(index)">确认报名</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Api from '../api/gongxu'

export default {
  components: {
    
  },
  props: ['show', 'zyYear'],
  data() {
    return {
      options: [],
      year: '',
      year_copy: '',
      userInfo: {},
      list: [],
      rote: 30
    };
  },
  computed: {},
  watch: {
    
  },
  created () {
    this.getYears()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getYears () {
      Api.getYears().then(res => {
        if (res.data.code == 1) {
          this.options =  res.data.data.years
          this.year = this.zyYear ? this.zyYear : res.data.data.year
          this.year_copy = this.year
          this.getCourseList()
        } else {
          this.$message.error('公需科目年份目录获取失败')
        }
      })
    },
    changeYear () {
      Api.setYears({
        year: this.year
      }).then(res => {
        if (res.data.code == 1) {
          this.year_copy = this.year
          this.setUserInfo()
          this.getCourseList()
        } else {
          this.year = this.year_copy
          this.$message.error("公需科目年份切换失败")
        }
      })
    },
    getCourseList () {
      Api.getCourseList({
        year: this.year
      }).then(res => {
        if (res.data.code == 1) {
          if (res.data.data.length > 0) {
            this.$router.replace({name: 'Gongxu'})
          } else {
            this.getBagList()
          }
        } else {
          this.$message.error("公需科目课程列表获取失败")
        }
      })
    },
    getBagList () {
      Api.getBagList({
        year: this.year
      }).then(res => {
        if (res.data.code == 1) {
          this.list = res.data.data
          if (this.list.length == 0) {
            let msg = '您已报名'+this.year+'年公需科目，课程暂未发布，请等待课程上线再进入学习。'
            if (this.year == 2020) {
              msg = '2020年公需科目无课程学习，广东省执业药师注册从2021年开始提供公需科目。'
            }
            this.$alert(msg, '温馨提示', {
              confirmButtonText: '确认'
            });
          }
        } else{
          this.$message.error('公需科目课程包获取失败')
        }
      })
    },
    handleStudy (index) {
      let userInfo = localStorage.getItem("userInfo")
      userInfo = JSON.parse(userInfo)
      if (userInfo.xuanke != 1) {
        this.$confirm('请先购买专业课后，再来选择公需科目学习（免费）。', '重要提示', {
          center: true,
          showCancelButton: false,
          confirmButtonText: '知道了',
        })
        return;
      }
      this.$router.push({name: 'Affirm', query: {index: index, year: this.year}})
    },
  },
}
</script>

<style lang='less' scoped>
.xuexi {
  .heard {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .year {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    > span {
      font-weight: bold;
      margin-right: 15px;
    }
  }
  .nav {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px;
    .prompt {
      margin-bottom: 30px;
      > div {
        margin-top: 10px;
      }
      > span {
        color: red;
        display: block;
        margin-top: 10px;
        font-weight: bold;
      }
    }
    .code {
      padding: 30px 0 30px 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #D8D8D8;
      img {
        width: 120px;
      }
    }
  }
  .list {
    padding: 10px 20px 30px;
    border-top: 1px solid #f3f3f3;
    > div {
      padding: 20px 0;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .content {
        margin-right: 20px;
        background-image: url("../assets/img/beij.png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        width: 360px;
        height: 220px;
        padding: 0 35px;
        h2, > div {
          color: @default;
          margin-bottom: 15px;
        }
        > div {
          font-size: 22px;
          white-space: wrap;
          word-break: break-all;
          text-align: center;
        }
        span {
          width: 40%;
          height: 25px;
          line-height: 25px;
          border-radius: 12px;
          text-align: center;
          background-color: @default;
          color: #f3f3f3;
          font-size: 14px;
        }
      }
      > div:last-child {
        width: 32%;
      }
    }
  }
}
</style>